<template>
	<div class="etapa-plan-tratament">
    <header class="flex flex-between">
      <h2 class="w100">etapa {{ data.id }}</h2>
      <div class="m-left-auto flex-center">
        <span v-if="data.tratamente.length">{{ data.tratamente.length }}t /&nbsp;&nbsp;</span>
        <span class="mleft4px">{{ percentageCompleted }}% - </span>
        <span class="mleft4px">{{ totalCost }} lei</span>
        <button cy="add-tratament"
                :disabled="activeTreatment"
                class="noborder"
                @click="addTratament()">
          <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5579 21.756C11.0059 21.756 10.5459 21.572 10.1779 21.204C9.80995 20.8053 9.62595 20.33 9.62595 19.778V12.878H2.72595C2.17395 12.878 1.71395 12.694 1.34595 12.326C0.977945 11.9273 0.793945 11.452 0.793945 10.9C0.793945 10.348 0.977945 9.88799 1.34595 9.51999C1.71395 9.15199 2.17395 8.96799 2.72595 8.96799H9.62595V2.06799C9.62595 1.51599 9.80995 1.05599 10.1779 0.687987C10.5459 0.319987 11.0059 0.135986 11.5579 0.135986C12.1099 0.135986 12.5699 0.319987 12.9379 0.687987C13.3059 1.05599 13.4899 1.51599 13.4899 2.06799V8.96799H20.3899C20.9419 8.96799 21.4019 9.16732 21.7699 9.56599C22.1686 9.93399 22.3679 10.3787 22.3679 10.9C22.3679 11.452 22.1686 11.9273 21.7699 12.326C21.4019 12.694 20.9419 12.878 20.3899 12.878H13.4899V19.778C13.4899 20.33 13.3059 20.8053 12.9379 21.204C12.5699 21.572 12.1099 21.756 11.5579 21.756Z" fill="#467863"/>
          </svg>
        </button>
        <button @click="handleSetVisibility(false)"
                v-if="data.isHidden"
                class="noborder">
          <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.257 10.962C21.731 11.582 21.731 12.419 21.257 13.038C19.764 14.987 16.182 19 12 19C7.81801 19 4.23601 14.987 2.74301 13.038C2.51239 12.7411 2.38721 12.3759 2.38721 12C2.38721 11.6241 2.51239 11.2589 2.74301 10.962C4.23601 9.013 7.81801 5 12 5C16.182 5 19.764 9.013 21.257 10.962Z" stroke="#467863" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#467863" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <line x1="1.43666" y1="19.1738" x2="23.4367" y2="4.17377" stroke="#467863" stroke-width="2"/>
          </svg>
        </button>
        <button @click="handleSetVisibility(true)"
                v-else
                class="noborder">
          <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.257 10.962C21.731 11.582 21.731 12.419 21.257 13.038C19.764 14.987 16.182 19 12 19C7.81801 19 4.23601 14.987 2.74301 13.038C2.51239 12.7411 2.38721 12.3759 2.38721 12C2.38721 11.6241 2.51239 11.2589 2.74301 10.962C4.23601 9.013 7.81801 5 12 5C16.182 5 19.764 9.013 21.257 10.962Z" stroke="#467863" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#467863" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
           </svg>
        </button>
      </div>
    </header>

    <ul cy="tratamente">
      <li v-for="(tratament, index) in data.tratamente"
          :key="index"
          class="flex flex-between">
        <span class="index">{{ index + 1 }}.</span>
        <TratamentPT :etapa-id="data.id"
                     :data="tratament" />
      </li>
    </ul>
	</div>
</template>

<script>
  import TratamentPT from './TratamentPT'
	export default {
		name: 'EtapaPlanTratament',
    components: { TratamentPT },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    computed: {
      activeTreatment () {
        return this.$store.state.planTratamente.treatmentState.isNew
      },
      percentageCompleted () {
        const tratamente = this.data.tratamente
        const length = tratamente.length
        const finalizate = tratamente.reduce((a, c) => c.status === 'finalizat' ? a += 1 : a, 0)
        const percentage = ((finalizate / length) * 100).toFixed()
        return percentage > 0 ? percentage : 0
      },
      totalCost () {
        return this.data.tratamente.reduce((a, c) =>  a + (+c.tratament.pret || 0),0)
      }
    },
    methods: {
      addTratament () {
        this.$store.commit('ADD_TRATAMENT_TO_ETAPA', this.data.id)
      },
      handleEditTratament (treatmentId) {
        this.$store.commit('SET_EDIT_MODE', { etapaId: this.data.id, treatmentId })
      },
      handleSetVisibility (value) {
        this.$store.commit('SET_VISIBILITY_ETAPA', {
          idEtapa: this.data.id - 1,
          value});
        this.$store.dispatch('update_plan')
      }
    }
	}
</script>

<style lang="less">
	.etapa-plan-tratament {
    width: 700px;
    h2 {
      padding: 8px 16px;
      color: #3E3E3E;
      font-size: 2rem;
    }
    header > div {
      color: #515050;
      font-size: 2rem;
      flex: 1 0 auto;
      button {
        margin-left: 8px;
        background-color: transparent;
        cursor: pointer;
        transition: transform 0.1s ease-in-out;
        &:not([disabled]):hover {
          transform: scale(1.1);
        }
        &[disabled] {
          opacity: 0.5;
          cursor: default;
        }
      }
    }
    span.index {
      width: 30px;
      font-size: 2rem;
      padding-left: 12px;
      padding-top: 6px;
    }
	}
</style>
