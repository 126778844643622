<template>
  <div class="select-doctors">
    <div class="select-doctors-wrapper" v-for="coordinator in coordinators">
      <div class="doctor-details"
           @click="handleSelectCoordinator(coordinator.name)"
      >
        <img :src="require(`../assets/doctors/${coordinator.img}`)"
             :class="{'selected-coordinator' : coordonator === coordinator.name}"
             alt="Premium Dental">
        <p>{{ coordinator.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SelectDoctors',

    props: {
      medicCoordonator: String
    },
    data () {
      return {
        coordinators: [
          {
            name: 'Sebastian Miclea',
              occupation: 'Estetică dentară, implantologie digitală',
              img: 'Sebastian-Miclea.png'
          },
          {
            name: 'Adriana Jula',
              occupation: 'Asistent',
              img: 'Adriana-Jula.png'
          }
        ],
        selectedCoordinator: ''
      }
    },

    computed: {
      coordonator () {
        this.selectedCoordinator = this.medicCoordonator
        return this.selectedCoordinator
      }
    },

    methods: {
      handleSelectCoordinator (name) {
        this.selectedCoordinator = name
        this.$emit('handle-medic-coordonator', this.selectedCoordinator)
      }
    }
  }
</script>

<style lang="less">
  .select-doctors {
    display: flex;
    //justify-content: space-evenly;
    //flex-wrap: wrap;
    width: 700px;
    .select-doctors-wrapper {
      margin-bottom: 30px;
      margin-right: 30px;
      .doctor-details {
        padding: 1rem;
        cursor: pointer;
        img {
          border: solid 0.2px #D1D9E6;
          border-radius: 50%;
          position: relative;
          width: 125px;
          height: 100%;
          left: 50%;
          transform: translateX(-50%);
          margin-bottom: 1rem;
        }

        .selected-coordinator {
          border: solid 2px #07358A;
        }

        p {
          text-align: center;
          font-size: 1.5rem;
        }

        &:hover  img {
          border: solid 2px #07358A;
        }


      }

    }


  }
</style>
